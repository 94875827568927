import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/business_consulting.jpg'


const pageTitle = "Business Consulting";
const pageSlug = "business-consulting";


class BusinessConsulting extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Let’s work together to transform your business and create and inspirational future for your organization.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                  <p>There is an influx of new disruptive forces impacting industries in a way that has not been seen before. Competition has arisen in previously blue ocean regions. New technologies compete with the old ways in which work is done and reshape specific understandings of efficiency and productivity in the past. Customer expectations continue to rise at a revolutionary pace through this. Because of the following issues, some groups have trouble adjusting to this fast-paced and ever-evolving climate.</p>
                  
                  <ul>
                    <li>A lack of understanding of market dynamics as well as feedback from customers / business.</li>
                    <li>Expenses of maintenance, fixed costs, and costs of inheritance.</li>
                    <li>A scarcity of quantitative and insightful data to support decision-making.</li>
                    <li>Subpar policy implementation, inability to coordinate plan and execution of strategy.</li>
                    <li>Lack of versatility in business and IT.</li>
                    <li>To build compelling goods, services and operating processes, lack of innovation.</li>
                  </ul>
                  <p>More than ever before, the demand to compete in today's market pushes companies to operate at extremely high performance levels and to push higher levels of innovation to stay ahead of the competition.</p>

                  <span className="break"></span>

                  <h6>We constantly strive to help our customers navigate the challenges of business change, important areas include:
        </h6>
                  <ul>
                    <li>Ensuring the rapid delivery of technology and business solutions </li>
                    <li>Understanding business and customer expectations</li>
                    <li>Create and establish compelling inspiring visions</li>
                    <li>Increasing value realization with project portfolio alignment with a strong strategy</li>
                    <li>Inspiring relevant individuals to transform your business culture</li>
                  </ul>
                  <p>How are we going to help you?
Our consulting services at Monera Technologies are customized to meet the real needs of the transformation needs of your company, from end to end.
  </p>
                  <span className="break"></span>
                  <span className="break"></span>

                  <h4>We can work  together to develop and grow your business model. Find out more about our solutions: 
                  </h4>
                  <span className="break"></span>
                  <p><strong><a href="">Operational and Process Excellence:</a></strong> We help you develop and build first-class processes that can produce tangible and sustainable results throughout your company.</p>
                  <p><strong><a href="">Enterprise Program Management:</a></strong> Allowing you to rapidly and agilely develop and execute plans and programs that create value.
                  </p>
                  <p><strong><a href="">Management of people and change:</a></strong> We assist you in supporting and inspiring your company so that your workers and community can flourish.</p>
                  <p><strong><a href="">Business strategy:</a></strong>Together we can understand your customer and business insights to create a vision that aligns with your strategy, providing you with a roadmap to guide your organization and help you transform it.</p>

 
                  
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinessConsulting
